<template>
    <div>
        <b-modal id="excel-import-modal" title="Charger un fichier d'inscription" @hidden="reset">
            <template #default>
                <b-form ref="form">
                    <div v-if="error" class="alert alert-warning">
                        Voici le
                        <a :href="`${VUE_APP_PLATEFORME_URL}/storage/classeUserErrors/${classeId}-${customerId}.xlsx`" download
                            >rapport d'erreurs</a
                        >
                        de votre import.
                    </div>
                    <b-overlay :show="loading" rounded="sm">
                        <b-form-group
                            :description="$t('FORM.INFO.FILE', { format: 'Excel (.xls)' })"
                            :label="$t('FORM.LABEL.FILE')"
                            :state="validateState('file')"
                            label-for="file"
                        >
                            <b-form-file
                                v-model="form.file"
                                id="file"
                                name="file"
                                v-validate="{ required: true }"
                                accept=".xls,.xlsx"
                                :state="validateState('file')"
                                :browse-text="$t('CONTROLLERS.BROWSE')"
                                :placeholder="$t('FORM.INPUT.FILE')"
                                aria-describedby="file-feedback"
                                data-vv-as="Excel"
                            >
                            </b-form-file>
                            <b-form-invalid-feedback id="file-feedback">
                                <div :key="error" v-for="error in veeErrors.collect('file')">{{ error }}</div>
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group :label="$t('FORM.LABEL.USERS_CUSTOMER')" label-for="customers-select">
                            <b-form-select
                                data-vv-as="Établissement"
                                v-validate="{ required: true }"
                                :state="validateState('customers-select')"
                                id="customers-select"
                                name="customers-select"
                                v-model="form.customer_id"
                                :options="customersOptions"
                            />
                            <b-form-invalid-feedback id="customers-feedback">
                                <div :key="error" v-for="error in veeErrors.collect('customers-select')">{{ error }}</div>
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-overlay>
                </b-form>
            </template>
            <template #modal-footer>
                <a :href="`${publicPath}media/import-model.xlsx`" download class="btn btn-light">{{ $t('CONTROLLERS.DOWNLOAD_MODEL') }}</a>
                <b-button variant="primary" @click="send()" :disabled="loading">{{ $t('CONTROLLERS.IMPORT') }}</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/core/services/api.service';
import { LIST_ALL_CLIENT } from '@/core/services/store/api/client.service';
import { validationErrorMsg } from '@/core/services/validation.service';

export default {
    props: {
        customerId: {
            type: Number,
            required: true
        },
        classeId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            VUE_APP_PLATEFORME_URL: process.env.VUE_APP_PLATEFORME_URL,
            error: false,
            form: {
                file: null,
                customer_id: null
            },
            loading: false,
            isValid: null,
            customersFields: [
                { key: 'code', label: 'Code' },
                { key: 'name', label: 'Établissement correspondant' }
            ],
            customersOptions: [],
            publicPath: process.env.BASE_URL
        };
    },
    watch: {
        customerId: function() {
            this.getCustomers();
        }
    },
    computed: {
        ...mapGetters({
            customers: 'getClients'
        })
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        getCustomers() {
            const data = {
                params: {
                    customer_id: this.customerId,
                    force: true
                }
            };
            this.$store.dispatch(LIST_ALL_CLIENT, data).then(result => {
                this.customersOptions = [
                    {
                        value: null,
                        text: 'Aucun établissement sélectionné'
                    }
                ];
                result &&
                    result.data &&
                    result.data.data.map(customer => {
                        this.customersOptions.push({
                            value: customer.id,
                            text: `${customer.name} (${customer.code})`
                        });
                    });
            });
        },
        async send() {
            this.error = false;
            const result = await this.$validator.validateAll();

            if (!result) return;
            this.loading = true;
            let data = new FormData();
            data.append('customer_id', this.form.customer_id);
            data.append('classe_id', this.classeId);
            data.append('gestionnaire_id', this.customerId);
            data.append('file', this.form.file);
            ApiService.post('academy/classeUsers/import', data)
                .then(response => {
                    if (response.data && ((response.data.errors && response.data.errors.length > 0) || response.data.imported === 0)) {
                        this.$bvToast.toast(`${response.data.imported} utilisateurs ont été importés.`, {
                            title: 'Attention : ',
                            variant: 'warning',
                            solid: true
                        });
                        this.$emit('updateClientsClasses');
                        this.error = true;
                    } else {
                        this.$bvToast.toast('Le fichier a été entièrement importé.', {
                            title: 'Succès : ',
                            variant: 'success',
                            solid: true
                        });
                        this.$emit('updateClientsClasses');
                        this.$bvModal.hide('excel-import-modal');
                    }
                    this.form = {
                        file: null,
                        customer_id: null
                    };
                    this.$validator.reset();
                })
                .catch(error => {
                    this.veeErrors.add(validationErrorMsg(error));
                    this.form = {
                        file: null,
                        customer_id: null
                    };
                    this.$validator.reset();
                })
                .finally(() => {
                    this.loading = false;
                    this.form = {
                        file: null,
                        customer_id: null
                    };
                });
        },
        reset() {
            this.form = {
                file: null,
                customer_id: null
            };
            this.error = false;
            this.$validator.reset();
        }
    }
};
</script>
