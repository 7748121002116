import moment from 'moment';

const textarea = value => value != '<p></p>';

const date = value => {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true;
    }
    return moment(value, 'DD/MM/YYYY', true).isValid();
};

const validationErrorMsg = error => {
    if (error && error.response && error.response.data && error.response.data.errors) {
        let listErrors = [];
        for (const [key, value] of Object.entries(error.response.data.errors)) {
            value.forEach(v => {
                listErrors.push({
                    field: key,
                    msg: v
                });
            });
        }
        return listErrors;
    }
};

export { textarea, date, validationErrorMsg };
